import PrivacyDelayCalcPage from 'Views/privacy/PrivacyDelayCalcPage'
import React from 'react'
import { graphql } from 'gatsby'

const PrivacyDelayCalc = () => {
  return <PrivacyDelayCalcPage />
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default PrivacyDelayCalc
